import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const Container = styled.section`
  margin-top: 29px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  @media (max-width: 1440px) {
    margin-right: 29px;
    margin-left: 29px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 499px) {
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 795px;
  min-width: 400px;

  height: 470px;

  background-color: white;

  border: 1px solid ${colors.blue200};
  border-radius: 8.4px;

  .gatsby-image-wrapper {
    align-self: flex-end;
  }

  @media (max-width: 1330px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 806px;

    .gatsby-image-wrapper {
      align-self: end;
    }
  }

  @media (max-width: 900px) {
    max-width: 100%;
  }

  @media (max-width: 499px) {
    min-width: unset;
  }
`;

export const LeftText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 40px;
  height: 100%;
  max-height: 392px;

  h2 {
    width: 100%;

    font-size: 32px;
    font-weight: 700;

    color: ${colors.blue400};

    line-height: 40px;
  }

  p {
    margin-top: 15px;

    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: ${colors.blue300};

    max-width: 287px;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 40px;
    width: 182px;

    border-radius: 8.4px;

    background-color: ${colors.blue400};

    color: white;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }

    img {
      margin-left: 10px;
    }
  }

  div + div {
    align-self: flex-end;
  }

  @media (max-width: 1330px) {
    margin-left: 0px;
    width: 100%;
    height: fit-content;
    padding-right: 29px;
    margin-top: 50px;

    p {
      max-width: 100%;
    }

    a {
      margin-top: 29px;
    }
  }

  @media (max-width: 499px) {
    padding-left: 20px;
    h2 {
      margin: 0px;
      padding: 0px;
    }

    p {
      max-width: 100%;
    }
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  width: 100%;
  max-width: 561px;
  min-width: 400px;

  border: 1px solid ${colors.blue200};
  border-radius: 8.4px;

  height: 470px;

  background-color: white;

  @media (max-width: 1440px) {
    margin-left: 29px;
  }

  @media (max-width: 1330px) {
    height: 806px;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: 900px) {
    margin-top: 29px;
    margin-left: 0px;

    height: 724px;

    max-width: none;
    min-width: none;
  }

  @media (max-width: 499px) {
    margin-top: 16px;
    min-width: unset;

    p {
      max-width: 100%;
    }
  }
`;

export const RightText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  max-width: 241px;

  height: 100%;
  max-height: 392px;

  align-self: center;
  justify-self: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 160px;

    h2 {
      font-size: 32px;
      font-weight: 700;
      color: ${colors.blue400};

      line-height: 40px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: ${colors.blue300};
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 40px;
    width: 182px;

    border-radius: 8.4px;

    background-color: ${colors.blue400};

    color: white;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }

    img {
      margin-left: 10px;
    }
  }

  @media (max-width: 1330px) {
    margin-top: 50px;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    justify-content: flex-start;

    height: fit-content;

    div {
      height: fit-content;

      p {
        margin-top: 15px;
      }
    }

    a {
      margin-top: 29px;
    }
  }
`;
