import cnpjValidator from './cnpjValidator';
import cpfValidator from './cpfValidator';

export const validateCPForCNPJ = (cpfOrCnpj: string | undefined): boolean => {
  if (!cpfOrCnpj) {
    return false;
  }

  if (cpfOrCnpj.length === 18) {
    const cnpjIsValid = cnpjValidator(cpfOrCnpj);

    if (cnpjIsValid) {
      return true;
    }

    return false;
  } else if (cpfOrCnpj.length === 14) {
    const cpfIsValid = cpfValidator(cpfOrCnpj);

    if (cpfIsValid) {
      return true;
    }

    return false;
  } else {
    return false;
  }
};
