import styled from 'styled-components';
import { colors } from '../GlobalStyle';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 100vw;

  background-color: ${colors.gray100};

  height: fit-content;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 1440px;

  height: fit-content;
  margin-bottom: 60px;
`;

export const SectionWrapper = styled.section`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 1380px;

  height: fit-content;
`;
