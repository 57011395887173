import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const Container = styled.div``;

export const HeroSectionLeftText = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  margin-left: 117px;

  height: fit-content;
  max-height: 297px;

  width: 100%;
  max-width: 486px;

  text-align: left;

  top: 120px;

  h2 {
    font-size: 72px;
    line-height: 84px;
    font-weight: 700;

    color: ${colors.blue400};

    span {
      color: ${colors.blue300};
    }
  }

  div {
    margin-top: 20px;

    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:nth-child(1) {
        min-width: 126px;
        width: fit-content;
      }

      &:nth-child(2) {
        min-width: 200px;
        width: fit-content;
      }

      &:nth-child(3) {
        min-width: 100px;
        width: fit-content;
      }

      h3 {
        font-size: 50px;
        font-weight: 700;
        line-height: 42px;

        color: ${colors.blue400};
      }

      p {
        font-size: 16px;
        font-weight: 500;

        color: ${colors.blue400};
      }
    }
  }

  @media (max-width: 1380px) {
    max-width: 380px;

    h2 {
      font-size: 56px;
      line-height: 75px;
    }

    div {
      margin-top: 20px;

      div {
        &:nth-child(1) {
          min-width: 100px;
          width: fit-content;
        }

        &:nth-child(2) {
          min-width: 200px;
          width: fit-content;
        }

        &:nth-child(3) {
          min-width: 100px;
          width: fit-content;
        }

        h3 {
          font-size: 41px;
          line-height: 37px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    margin-left: 80px;
  }

  @media (max-width: 1100px) {
    margin-left: 60px;
  }

  @media (max-width: 1030px) {
    margin-left: 40px;

    h2 {
      font-size: 48px;
      line-height: 60px;
    }

    div {
      margin-top: 10px;

      max-width: 315px;

      div {
        h3 {
          font-size: 36px;
          line-height: 31px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 910px) {
    max-height: none;

    top: 8%;

    div {
      width: fit-content;
      flex-direction: column;

      div {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        & + div {
          margin-top: 20px;
        }
      }
    }
  }

  @media (max-width: 830px) {
    margin-left: 29px;

    h2 {
      font-size: 37px;
      line-height: 46px;
      max-width: 263px;
    }

    div div {
      h3 {
        font-size: 30px;
        line-height: 28px;
      }

      p {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 730px) {
    h2 {
      font-size: 28px;
      line-height: 37px;
      max-width: 257px;
    }

    div div {
      h3 {
        font-size: 25px;
        line-height: 22px;
      }

      p {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 630px) {
    h2 {
      font-size: 26px;
      line-height: 34px;
      max-width: 257px;
    }

    div div {
      h3 {
        font-size: 22px;
        line-height: 20px;
      }

      p {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 590px) {
    h2 {
      font-size: 22px;
      line-height: 24px;
      max-width: 200px;
    }

    div div {
      margin-top: 0px;

      h3 {
        font-size: 18px;
        line-height: 20px;
      }

      p {
        font-size: 13px;
      }

      & + div {
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 28px;
      line-height: 34px;
      max-width: 200px;
    }

    div div {
      margin-top: 12px;

      h3 {
        font-size: 20px;
        line-height: 20px;
      }

      p {
        font-size: 15px;
      }

      & + div {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 340px) {
    h2 {
      font-size: 22px;
      line-height: 34px;
      max-width: 200px;
    }

    div div {
      margin-top: 0px;

      h3 {
        font-size: 18px;
        line-height: 20px;
      }

      p {
        font-size: 13px;
      }

      & + div {
        margin-top: 8px;
      }
    }
  }
`;

export const DesktopImageContainer = styled.div`
  display: block;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const MobileImageContainer = styled.div`
  display: none;

  @media (max-width: 500px) {
    display: block;
  }
`;
