import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import Slide from './Slide';

import { CarouselContainer, SliderContainer } from './styles';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface IDadosGerais {
  unidades: number;
  cidadesAtendidas: number;
  estadosAtendidos: number;
}

interface IImage {
  id: string;
  imageFile: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

interface ISlideImageRaw {
  desktop: IImage;
  mobile: IImage;
  alt_text: string;
  link_externo: string;
  order: number;
}

interface ISlideImage {
  desktopImage: IImage;
  mobileImage: IImage;
  alt: string;
  link: string;
}

const HomeSlider = (): JSX.Element => {
  const { directus } = useStaticQuery(query);

  const dadosGerais = useMemo((): IDadosGerais => {
    return {
      unidades: directus.dados_gerais.unidades,
      cidadesAtendidas: directus.dados_gerais.cidades_atendidas,
      estadosAtendidos: directus.dados_gerais.estados_atendidos,
    };
  }, [directus.dados_gerais]);

  const slideImages = useMemo(
    (): ISlideImage[] =>
      directus.slider.map((slide: ISlideImageRaw) => ({
        desktopImage: slide.desktop,
        mobileImage: slide.mobile,
        alt: slide.alt_text,
        link: slide.link_externo,
        order: slide.order,
      })),
    [directus.slider]
  );

  slideImages.sort(compare);

  return (
    <SliderContainer>
      <CarouselContainer autoPlay infiniteLoop emulateTouch showThumbs={false}>
        {slideImages?.map((slideImage, index) => (
          <Slide
            key={index}
            dadosGerais={index === 1 ? dadosGerais : undefined}
            image={slideImage}
          />
        ))}
      </CarouselContainer>
    </SliderContainer>
  );
};

export default HomeSlider;

const query = graphql`
  query {
    directus {
      slider {
        alt_text
        order
        link_externo

        desktop {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }

        mobile {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      dados_gerais {
        cidades_atendidas
        unidades
        estados_atendidos
      }
    }
  }
`;

function compare(a: any, b: any) {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
}
