import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { navigate } from 'gatsby';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';

import DefaultInput from '@/components/Inputs/DefaultInput';

import LupaIcon from '@/images/paginas/rastreamento/lupa.svg';
import SearchIcon from '@/images/icons/icon-search.svg';
import SearchIconDisabled from '@/images/icons/icon-search-disabled.svg';

import { removeAllNonNumberCharacters } from '@/utils/removeAllNonNumberCharacters';
import { getValidationErrors } from '@/utils/getValidationErrors';
import { validateCPForCNPJ } from '@/utils/cpfOrCnpjValidator/cnpjAndCpf';

import { Container, Form, TipoPagadorContainer } from './styles';

interface IFormData {
  cpfOrCnpj: string;
  notaFiscal: number;
}

const MenuRastreamentoSection: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [tipo, setTipo] = useState('');
  const [cpfOrCnpj, setCpfOrCnpj] = useState('');
  const [notaFiscal, setNotaFiscal] = useState('');

  const [tipoPagadorErrorMessage, setTipoPagadorErrorMessage] = useState('');

  const isCpfOrCnpj = useMemo(() => {
    const parsedCpfOrCnpj = removeAllNonNumberCharacters(cpfOrCnpj);

    if (parsedCpfOrCnpj.length === 11) {
      return 'cpf';
    }

    if (parsedCpfOrCnpj.length === 14) {
      return 'cnpj';
    }

    return '';
  }, [cpfOrCnpj]);

  useEffect(() => {
    if (isCpfOrCnpj === 'cpf') {
      setTipo('destinatario');
    } else {
      setTipo('');
    }
  }, [isCpfOrCnpj]);

  useEffect(() => {
    setTipoPagadorErrorMessage('');
  }, [tipo]);

  const handleFormSubmit = useCallback(
    async (formData: IFormData) => {
      const { cpfOrCnpj, notaFiscal } = formData;

      const schema = yup.object().shape({
        cpfOrCnpj: yup
          .string()
          .required('*CPF/CNPJ obrigatório!')
          .test(
            'test-cpfOrCnpj-validation',
            '*CPF/CNPJ inválido',
            (cpfOrCnpj) => validateCPForCNPJ(cpfOrCnpj)
          ),
        tipoPagador: yup.string().required('*Insira o tipo de pagador.'),
        notaFiscal: yup
          .string()
          .test(
            'test-notaFiscal-validation',
            '*Nota fiscal inválida',
            (notaFiscal) => {
              if (isCpfOrCnpj === 'cpf') {
                if (notaFiscal && !Number(notaFiscal)) {
                  return false;
                }

                return true;
              } else {
                if (notaFiscal && !Number(notaFiscal)) {
                  return false;
                }

                return !!notaFiscal;
              }
            }
          ),
      });

      try {
        formRef.current?.setErrors({});
        setTipoPagadorErrorMessage('');

        await schema.validate(
          { cpfOrCnpj, notaFiscal, tipoPagador: tipo },
          {
            abortEarly: false,
          }
        );
      } catch (yupErr) {
        const errors = getValidationErrors(yupErr);

        if (errors.tipoPagador) {
          setTipoPagadorErrorMessage(errors.tipoPagador);
        }

        formRef.current?.setErrors(errors);
        return;
      }

      const parsedCpfOrCnpj = removeAllNonNumberCharacters(cpfOrCnpj);

      if (isCpfOrCnpj === 'cpf' && !notaFiscal) {
        return navigate(
          `https://cliente.generoso.com.br/rastrear/encomendas?cpf=${parsedCpfOrCnpj}`
        );
      }

      return navigate(
        `https://cliente.generoso.com.br/rastrear/resultado?cnpj=${parsedCpfOrCnpj}&tipo=${tipo}&notaFiscal=${notaFiscal}`
      );
    },
    [tipo, isCpfOrCnpj]
  );

  const disableSubmitButton = useMemo(() => {
    if (isCpfOrCnpj === 'cpf') {
      return !cpfOrCnpj || !tipo;
    }

    return !cpfOrCnpj || !tipo || !notaFiscal;
  }, [cpfOrCnpj, tipo, notaFiscal, isCpfOrCnpj]);

  return (
    <Container>
      <img
        src={LupaIcon}
        alt="Ícone de um fone de ouvido representando o atendimento."
      />
      <p>Rastreie sua mercadoria:</p>

      <Form
        ref={formRef}
        onSubmit={handleFormSubmit}
        autoComplete="new-password"
        action="/rastreamento/resultado"
      >
        <DefaultInput
          name="cpfOrCnpj"
          labelName="CPF ou CNPJ"
          placeholder="Informe o CPF ou o CNPJ"
          autoComplete="off"
          mask={['999.999.999-99', '99.999.999/9999-99']}
          setInputValue={setCpfOrCnpj}
        />
        <TipoPagadorContainer error={!!tipoPagadorErrorMessage}>
          <select
            value={tipo}
            onChange={(e) => {
              setTipo(e.target.value);
            }}
            disabled={isCpfOrCnpj === 'cpf'}
          >
            <option hidden value="">
              Tipo
            </option>
            <option value="remetente">Remetente</option>
            <option value="destinatario">Destinatario</option>
          </select>

          <span>{tipoPagadorErrorMessage}</span>
        </TipoPagadorContainer>
        <DefaultInput
          name="notaFiscal"
          labelName="Nota fiscal"
          placeholder="Nota fiscal"
          autoComplete="off"
          onChange={(e) => {
            setNotaFiscal(e.target.value);
          }}
        />

        <button type="submit" disabled={disableSubmitButton}>
          Buscar
          <img
            src={disableSubmitButton ? SearchIconDisabled : SearchIcon}
            alt="Ícone de lupa de busca."
          />
        </button>
      </Form>
    </Container>
  );
};

export default MenuRastreamentoSection;
