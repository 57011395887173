import styled from 'styled-components';
import { colors } from '../../../../styles/GlobalStyle';

export const Container = styled.div`
  margin-top: 29px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  @media (max-width: 1440px) {
    flex-wrap: wrap;
    margin-right: 29px;
    margin-left: 29px;
  }

  @media (max-width: 1380px) {
    max-width: 660px;

    height: 445px;

    margin-right: 0px;
    margin-left: 0px;
  }

  @media (max-width: 730px) {
    max-width: 440px;

    height: 680px;

    margin-right: 0px;
    margin-left: 0px;
  }

  @media (max-width: 499px) {
    max-width: 316px;
    height: 490px;
  }

  @media (max-width: 320px) {
    max-width: 256px;
    height: 400px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  height: 210px;
  width: 100%;
  max-width: 210px;

  background-color: #2150a3;

  border-radius: 8.4px;

  h2 {
    margin-top: 15px;
    font-size: 32px;
    color: white;
  }

  p {
    width: 100%;
    max-width: 148px;
    font-size: 15px;
    font-weight: 500;

    justify-self: flex-start;

    color: ${colors.blue200};
  }

  @media (max-width: 499px) {
    max-width: 150px;
    height: 150px;

    img {
      width: 30px;
    }

    h2 {
      font-size: 18px;
    }

    p {
      font-size: 10px;
      max-width: 103px;
    }
  }

  @media (max-width: 320px) {
    max-width: 120px;
    height: 120px;
  }
`;

// export const SectionFour = styled.div`
//   margin-top: 25px;

//   width: 100%;
//   max-width: 1380px;

//   min-height: fit-content;

//   @media (max-width: 1440px) {
//     margin: 0 29px;
//   }

//   @media (max-width: 500px) {
//     margin: 0;
//   }
//   @media (max-width: 499px) {
//     margin: 0 16px;
//   }

//   @media (max-width: 320px) {
//     margin: 0 8px;
//   }
// `;
