import React from 'react';
import CountUp from 'react-countup';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import {
  Container,
  HeroSectionLeftText,
  DesktopImageContainer,
  MobileImageContainer,
} from './styles';

interface IImage {
  id: string;
  imageFile: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

interface ISlideImage {
  desktopImage: IImage;
  mobileImage: IImage;
  alt: string;
  link: string;
}

interface SlideProps {
  image: ISlideImage;
  dadosGerais?: {
    unidades: number;
    cidadesAtendidas: number;
    estadosAtendidos: number;
  };
}

const Slide: React.FC<SlideProps> = ({ dadosGerais, image }) => {
  const desktopImage =
    image.desktopImage.imageFile.childImageSharp.gatsbyImageData;

  const mobileImage =
    image.mobileImage.imageFile.childImageSharp.gatsbyImageData;

  return (
    <Container>
      <DesktopImageContainer>
        {image.link ? (
          <a href={image.link} target="_blank" rel="noreferrer">
            <GatsbyImage image={desktopImage} alt={image.alt} />
          </a>
        ) : (
          <GatsbyImage image={desktopImage} alt={image.alt} />
        )}
      </DesktopImageContainer>

      <MobileImageContainer>
        {image.link ? (
          <a href={image.link} target="_blank" rel="noreferrer">
            <GatsbyImage image={mobileImage} alt={image.alt} />
          </a>
        ) : (
          <GatsbyImage image={mobileImage} alt={image.alt} />
        )}
      </MobileImageContainer>

      {dadosGerais && (
        <HeroSectionLeftText>
          <h2>
            Quer chegar <span>mais rápido?</span>
          </h2>

          <div>
            <div>
              <h3>
                <CountUp prefix="+" end={dadosGerais?.unidades || 0} />
              </h3>

              <p>Unidades</p>
            </div>

            <div>
              <h3>
                <CountUp prefix="+" end={dadosGerais?.cidadesAtendidas || 0} />
              </h3>
              <p>Cidades atendidas</p>
            </div>

            <div>
              <h3>
                <CountUp end={dadosGerais?.estadosAtendidos || 0} />
              </h3>
              <p>Estados</p>
            </div>
          </div>
        </HeroSectionLeftText>
      )}
    </Container>
  );
};

export default Slide;
