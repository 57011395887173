import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const Container = styled.section`
  margin-top: 29px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  max-width: 1380px;

  height: fit-content;

  @media (max-width: 1440px) {
    margin-right: 29px;
    margin-left: 29px;
  }

  @media (max-width: 1340px) {
    min-height: 180px;
    max-width: 690px;
  }

  @media (max-width: 750px) {
    min-height: 160px;
    max-width: 660px;
  }

  @media (max-width: 730px) {
    min-height: 261px;
    max-width: 437px;

    margin-right: 0px;
    margin-left: 0px;
  }

  @media (max-width: 499px) {
    justify-content: center;

    div + div {
      margin-top: 16px;
    }
  }
`;

export const Option = styled.div`
  display: flex;

  width: 100%;
  min-width: fit-content;
  max-width: 211px;

  height: 70px;

  background-color: white;

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px;

    div {
      display: flex;
      align-items: center;

      width: 100%;

      p {
        width: 100%;
        max-width: 93px;
        font-size: 15px;
        font-weight: 500;

        color: ${colors.blue400};
      }

      img.icon {
        margin: 10px;

        align-self: flex-start;
      }
    }

    img {
      margin-right: 10px;
      margin-top: 10px;
      align-self: flex-start;
    }
  }

  border: 1px solid ${colors.blue200};
  border-radius: 8.4px;

  &:hover {
    background-color: ${colors.blue100};
    border: 1px solid ${colors.blue300};

    transition: 0.3s;
  }

  @media (max-width: 499px) {
    justify-content: space-evenly;

    max-width: 100%;
    margin-right: 16px;
    margin-left: 16px;

    a {
      div {
        p {
          max-width: unset;
        }
      }
    }
  }
`;
