import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import IconNewPageWhite from '@/images/icons/icon-redirect-new-page-white.svg';

import { Container, Left, LeftText, Right, RightText } from './styles';
import { Link } from 'gatsby';

const CotacaoColetaSection = (): JSX.Element => {
  return (
    <Container>
      <Left>
        <LeftText>
          <div>
            <h2>Seja um cliente Generoso!</h2>
            <p>
              A Generoso oferece pra você uma vasta gama de oportunidades para
              ampliar suas vendas em todo Brasil.
            </p>
            <p>
              Nosso time comercial está pronto para atender a necessidade do seu
              negócio. Seja Generoso!
            </p>
          </div>
          <Link to="/contato">Quero ser cliente</Link>
        </LeftText>
        <StaticImage
          src="../../../../images/paginas/index/mulher-generoso-lado.png"
          quality={100}
          alt="Mulher generoso de lado"
          placeholder="blurred"
          width={550}
          height={555}
        />
      </Left>
      <Right>
        <RightText>
          <div>
            <h2>Agende sua coleta</h2>
            <p>
              Garantimos um serviço rápido e de qualidade desde a coleta, até a
              entrega.
            </p>
          </div>
          <a
            href="https://cliente.generoso.com.br/coleta/coletar"
            target="_blank"
            rel="noreferrer"
          >
            Agendar coleta
            <img src={IconNewPageWhite} alt="Nova página" />
          </a>
        </RightText>
        <StaticImage
          src="../../../../images/paginas/index/celular-entrega.png"
          quality={100}
          alt="Homem segurando um celular"
          placeholder="blurred"
        />
      </Right>
    </Container>
  );
};

export default CotacaoColetaSection;
