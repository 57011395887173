import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

import { colors } from '@/styles/GlobalStyle';

interface ITipoPagadorContainerProps {
  error: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 90px;
  margin-top: 2px;

  border: 1px solid ${colors.gray200};
  background-color: #fff;

  img {
    width: 48px;
    height: 48px;
  }

  > p {
    width: max-content;
    max-width: 203px;

    font-size: 16px;
    color: ${colors.gray600};

    margin: 0 24px;

    @media (max-width: 1302px) {
      max-width: 110px;
    }
  }

  @media (max-width: 1440px) {
    padding: 0px 30px;
  }

  @media (max-width: 875px) {
    display: none;
  }
`;

export const Form = styled(Unform)`
  width: 100%;
  max-width: 929px;

  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  div {
    margin: 0;
    margin-right: 11px;
    height: 48px;

    input {
      height: 48px;
    }
  }

  button {
    display: flex;
    align-items: center;

    width: 100%;
    max-width: 151.56px;
    height: 40px;

    padding: 8px 24px 9px 24px;

    background-color: ${colors.blue400};
    color: ${colors.white};

    border: none;
    border-radius: 8px;

    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;

    img {
      width: 15.56px;
      height: 15.56px;
      margin-left: 32px;
      margin-right: 25.73px;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: ${colors.gray200};
      color: ${colors.gray400};
    }
  }
`;

export const TipoPagadorContainer = styled.div<ITipoPagadorContainerProps>`
  width: 100%;
  max-width: 186px;

  select {
    border-radius: 8px;
    border: 1px solid ${(props) => (props.error ? colors.red : colors.gray300)};
    outline: 0;

    height: 48px;
    padding-left: 20px;

    width: 100%;
    max-width: 186px;

    font-size: 14px;

    background-color: transparent;
    color: ${colors.gray600};

    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 11px;

    &:active {
      border: 1px solid ${colors.blue400};
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  select + span {
    margin-left: 5px;
    font-size: 12px;
    height: 10px;

    color: ${colors.red};
  }
`;
