/* eslint-disable */
/* prettier-ignore */
import * as React from 'react';
import { Link } from 'gatsby';

import Icon2ViaBoleto from '@/images/paginas/index/icon-2-via-boleto.svg';
import IconAgendarColeta from '@/images/paginas/index/icon-agendar-coleta.svg';
import IconAtendimento from '@/images/paginas/index/icon-atendimento.svg';
import IconCotacao from '@/images/paginas/index/icon-cotacao.svg';
import IconNewPage from '@/images/paginas/index/icon-new-page.svg';
import IconPrevisaoEntrega from '@/images/paginas/index/icon-previsao-entrega.svg';
import IconRastreio from '@/images/paginas/index/icon-rastreio.svg';

import { Container, Option } from './styles';

const ServicesSection = (): JSX.Element => {
  return (
    <Container>
      <Option>
        <a
          href="https://cliente.generoso.com.br/coleta/coletar"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            <img
              src={IconAgendarColeta}
              alt="Ícone de caixa para agendar coleta"
              className="icon"
            />
            <p>Agendar coleta</p>
          </div>

          <img src={IconNewPage} alt="Ícone para abrir em nova página" />
        </a>
      </Option>
      <Option>
        <a
          href="https://cliente.generoso.com.br/cotacao/cotar"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            <img
              src={IconCotacao}
              alt="Ícone de conta simbolizando cotação"
              className="icon"
            />
            <p>Cotação</p>
          </div>

          <img src={IconNewPage} alt="Ícone para abrir em nova página" />
        </a>
      </Option>
      <Option>
        <a
          href="https://cliente.generoso.com.br/rastrear"
          target="_blank"
          rel="noreferrer"
        >               
          <div>
            <img
              src={IconRastreio}
              alt="Ícone de lupa para ir ao rastreio"
              className="icon"
            />
            <p>Rastreamento</p>
          </div>
        </a>
      </Option>
      <Option>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://cliente.generoso.com.br/prazos"
          >
          <div>
            <img
              src={IconPrevisaoEntrega}
              alt="Ícone de cronômeto para ir a previsão de entrega"
              className="icon"
            />
            <p>Previsão de entrega</p>
          </div>
          </a>
      </Option>
      <Option>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://cliente.generoso.com.br/faturas"
        >
          <div>
            <img
              src={Icon2ViaBoleto}
              alt="Ícone de folha para segunda via de boleto"
              className="icon"
            />
            <p>2ª via de boletos</p>
          </div>
        </a>
      </Option>
      <Option>
        <Link to="/atendimento">
          <div>
            <img
              src={IconAtendimento}
              alt="Ícone de celular para ir ao atendimento"
              className="icon"
            />
            <p>Atendimento</p>
          </div>
        </Link>
      </Option>
    </Container>
  );
};

export default ServicesSection;
