import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { Container, Left, Right } from './styles';

import IconRedirectForward from '@/images/paginas/index/icon-redirect-forward.svg';

const AGenerosoSection = (): JSX.Element => {
  return (
    <Container>
      <Left>
        <h2>Dedicação, compromisso e resultados.</h2>

        <div className="descricao">
          <p>
            Somos uma empresa 100% brasileira, nosso alvo é movimentar o seu
            negócio, ajudamos diariamente milhares de clientes a alcançar
            resultados significativos e desempenho comercial satisfatório.
          </p>
          <p>
            Hoje somos referência no transporte de carga fracionada, marcamos
            presença em grande parte do território nacional e estamos crescendo
            ainda mais para que haja sempre uma Generoso bem perto de você.
          </p>
        </div>

        <div className="mapa-brasil">
          <StaticImage
            src="../../../../images/paginas/index/mapa-brasil.png"
            alt="Mapa do Brasil com estados onde o serviço é atendido"
            quality={100}
            width={333}
            height={343}
            placeholder="none"
          />
        </div>

        <div className="link-area">
          <Link to="/sobre-nos">
            Conheça o Transporte Generoso
            <img src={IconRedirectForward} alt="Seta para frente" />
          </Link>
          {/* <SectionTwoLeftPlusCircle
          isLeft={isLeft}
          onClick={handleToggle}
        >
          <button>
            <span></span>
            <span></span>
          </button>
        </SectionTwoLeftPlusCircle> */}
        </div>
      </Left>
      <Right>
        <StaticImage
          src="../../../../images/paginas/index/mapa-brasil.png"
          alt="Mapa do Brasil com estados onde o serviço é atendido"
          quality={100}
          placeholder="blurred"
          width={504}
          height={519}
        />
      </Right>
    </Container>
  );
};

export default AGenerosoSection;
