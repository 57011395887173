import * as React from 'react';

import { Container } from './styles';

const TrabalheConoscoSection = (): JSX.Element => {
  return (
    <Container>
      <h2>Faça parte da equipe Generoso</h2>
      <p>
        Basta preencher o formulário e enviar seu currículo para passar pelo
        nosso processo de seleção.
      </p>
      <a
        href="https://transportegeneroso.solides.jobs"
        rel="noreferrer"
        target="_blank"
      >
        Trabalhe conosco
      </a>
    </Container>
  );
};

export default TrabalheConoscoSection;
