import styled from 'styled-components';
import { colors } from '../../../../styles/GlobalStyle';

export const Container = styled.div`
  margin-top: 29px;
  margin-bottom: 29px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;

  padding: 40px 0;

  border: 1px solid ${colors.blue200};
  border-radius: 8.4px;

  box-sizing: border-box;

  background-color: white;

  height: fit-content;

  h2 {
    width: 100%;
    max-width: 306px;

    font-size: 32px;
    font-weight: 700;
    color: ${colors.blue400};
    line-height: 38px;
  }

  p {
    font-size: 16px;
    font-weight: 500;

    width: 100%;
    max-width: 444px;

    color: ${colors.blue300};
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    outline: none;

    background-color: ${colors.blue400};

    width: 193px;
    height: 40px;

    color: white;

    font-size: 16px;
    font-weight: 500;

    border-radius: 11.2px;

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }
  }

  @media (max-width: 1440px) {
    margin-right: 29px;
    margin-left: 29px;
  }

  @media (max-width: 1040px) {
    flex-direction: column;
    text-align: center;

    p {
      margin-top: 15px;
      max-width: 300px;
    }

    a {
      margin-top: 20px;
    }
  }

  @media (max-width: 499px) {
    margin-right: 16px;
    margin-left: 16px;
  }

  @media (max-width: 320px) {
    h2 {
      font-size: 28px;
      line-height: 33px;
    }

    p {
      font-size: 12px;
      max-width: 240px;
    }
  }
`;
