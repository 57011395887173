import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CountUp from 'react-countup';

import AnosDeExperiencia from '@/images/paginas/index/anos-de-experiencia.svg';
import CidadesAntedidas from '@/images/paginas/index/cidades-atendidas.svg';
import Colaboradores from '@/images/paginas/index/colaboradores.svg';
import EntregasRealizadas from '@/images/paginas/index/entregas-realizadas.svg';
import ToneladasTransportadas from '@/images/paginas/index/toneladas-transportadas.svg';
import VolumesTransportados from '@/images/paginas/index/volumes-transportados.svg';

import { Container, Item } from './styles';

interface IDadosGerais {
  anos_de_experiencia: number;
  cidades_atendidas: number;
  colaboradores: number;
  entrega_realizadas: number;
  volumes_transportados: number;
  toneladas_transportadas: number;
  unidades: number;
  estados_atendidos: number;
}

const CompanyNumbersSection = (): JSX.Element => {
  const { directus } = useStaticQuery(query);

  const dadosGerais: IDadosGerais = directus.dados_gerais;

  return (
    <Container>
      <Item>
        <img src={AnosDeExperiencia} alt="Anos de experiência" />
        <h2>
          <CountUp prefix="+" end={dadosGerais.anos_de_experiencia} />
        </h2>
        <p>Anos de experiência</p>
      </Item>
      <Item>
        <img src={CidadesAntedidas} alt="Cidades atendias" />
        <h2>
          <CountUp prefix="+" end={dadosGerais.cidades_atendidas} />
        </h2>
        <p>Cidades atendidas</p>
      </Item>
      <Item>
        <img src={Colaboradores} alt="Colaboradores" />
        <h2>
          <CountUp prefix="+" end={dadosGerais.colaboradores} />
        </h2>
        <p>Colaboradores</p>
      </Item>
      <Item>
        <img src={EntregasRealizadas} alt="Entregas realizadas por ano" />
        <h2>
          <CountUp
            prefix="+"
            end={dadosGerais.entrega_realizadas}
            suffix="M"
            delay={1}
            duration={10}
          />
        </h2>
        <p>Entregas realizadas / ano</p>
      </Item>
      <Item>
        <img src={VolumesTransportados} alt="Volumes transportados por ano" />
        <h2>
          <CountUp
            prefix="+"
            end={dadosGerais.volumes_transportados}
            suffix="M"
            delay={1}
            duration={10}
          />
        </h2>
        <p>Volumes transportados / ano</p>
      </Item>
      <Item>
        <img
          src={ToneladasTransportadas}
          alt="Toneladas transportadas por ano"
        />
        <h2>
          <CountUp
            prefix="+"
            end={dadosGerais.toneladas_transportadas}
            suffix="k"
          />
        </h2>
        <p>Toneladas transportadas / ano</p>
      </Item>
    </Container>
  );
};

export default CompanyNumbersSection;

const query = graphql`
  query {
    directus {
      dados_gerais {
        anos_de_experiencia
        cidades_atendidas
        colaboradores
        entrega_realizadas
        volumes_transportados
        toneladas_transportadas
        unidades
        estados_atendidos
      }
    }
  }
`;
