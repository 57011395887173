import styled from 'styled-components';
import { colors } from '../../../../styles/GlobalStyle';

export const Container = styled.div`
  margin-top: 29px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  width: 100%;

  height: 100%;
  max-height: 600px;

  background-image: linear-gradient(to left, #74c3ff, #1f4cc5);

  border-radius: 8.4px;

  @media (max-width: 1440px) {
    margin-right: 29px;
    margin-left: 29px;
  }

  @media (max-width: 1045px) {
    flex-direction: column;
    max-height: none;

    padding: 50px 0px;
  }

  @media (max-width: 499px) {
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: fit-content;

  width: 100%;
  max-width: 464px;

  padding-left: 29px;

  text-align: left;

  h2 {
    width: 100%;
    max-width: 402px;

    color: white;

    font-size: 32px;
    font-weight: 700;

    line-height: 42px;
  }

  p {
    font-size: 16px;
    font-weight: 400;

    color: white;

    margin-top: 30px;
  }

  div.mapa-brasil {
    display: none;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: none;
    outline: none;
    border: none;

    width: 188px;

    text-transform: uppercase;

    font-size: 14px;
    font-weight: 500;

    width: fit-content;

    margin: 0px;
    margin-top: 40px;

    color: ${colors.blue200};

    &:hover {
      font-weight: bold;
      color: ${colors.blue200};
    }

    img {
      display: block;
      width: 14px;
      height: 14px;
      margin-left: 8px;
    }
  }

  @media (max-width: 1045px) {
    width: fit-content;
    max-width: none;

    div.mapa-brasil {
      display: block;

      margin-top: 40px;
    }

    a {
      margin-top: 10px;
    }

    p {
      padding-right: 20px;
      max-width: 400px;
    }
  }

  @media (max-width: 925px) {
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 320px) {
    padding-left: 16px;

    h2 {
      font-size: 26px;
      line-height: 34px;
    }

    p {
      font-size: 12px;
      margin-top: 10px;
    }

    a {
      font-size: 12px;
    }
  }
`;

export const Right = styled.div`
  margin-top: 40px;
  width: fit-content;

  @media (max-width: 1045px) {
    display: none;
  }
`;

// export const SectionTwoLeftPlusCircle = styled.div<ISectionTwo>`
//   display: none;

//   button {
//     color: #ecf0f1;
//     width: 33px;
//     height: 33px;
//     border: 0;
//     font-size: 1.5em;
//     position: relative;

//     border-radius: 50%;
//     background: rgba(255, 255, 255, 0.4);
//   }

//   span {
//     position: absolute;
//     transition: 300ms;
//     background: white;
//     border-radius: 2px;
//   }

//   span:first-child {
//     top: 25%;
//     bottom: 25%;
//     width: 10%;
//     left: 45%;
//   }

//   span:last-child {
//     left: 25%;
//     right: 25%;
//     height: 10%;
//     top: 45%;
//   }

//   span {
//     transform: ${(props) => (!props.isLeft ? 'rotate(90deg)' : null)};
//   }

//   span:last-child {
//     left: ${(props) => (!props.isLeft ? '50%' : '25%')};
//     right: ${(props) => (!props.isLeft ? '50%' : '25%')};
//   }

//   @media (max-width: 500px) {
//     display: block;
//   }

//   @media (max-width: 499px) {
//     align-self: flex-start;
//     margin-bottom: 20px;
//   }
// `;
